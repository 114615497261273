import * as React from "react"
import { graphql, navigate } from "gatsby"
import Seo from "../components/seo"
import Card from "../components/card"
import { useQuestionContext } from "../context/question"
import HeatMap from "../components/heatmap"

const QuestionPage = ({
  data: { question, questionDates },
  pageContext: { published },
}) => {
  const { processNext, setVisited, setQuestion, initialize, state } =
    useQuestionContext()

  const questionIds = questionDates?.questionIds

  React.useEffect(() => {
    if (!questionIds) return
    initialize(questionIds, published)
    setVisited(question, published)
  }, [questionIds, question, initialize, setVisited, published])

  React.useEffect(() => {
    setQuestion(question)
  }, [question, setQuestion])

  const checkResponse = (question, valid) => {
    setTimeout(() => {
      const nextId = processNext(question.id, published, valid)

      if (nextId) {
        return navigate(`/valencia/${published}/${nextId}`)
      }
      return navigate(`/valencia/${published}/completed`)
    }, 500)
  }

  return (
    <>
      <Seo title="Home" />
      <h1 className="text-2xl text-center mb-4 text-blue-900">{published}</h1>
      <HeatMap
        state={state}
        date={published}
        showScore={true}
        actualId={question.id}
      />
      <Card q={question} checkResponse={checkResponse} />
    </>
  )
}

export const query = graphql`
  query ($id: String, $published: String) {
    question(id: { eq: $id }) {
      id
      question
      responses
      type
      section
      valid
    }
    questionDates(published: { eq: $published }) {
      questionIds
    }
  }
`

export default QuestionPage
