import React from "react"

const One = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 16 16">
    <path
      stroke="#000"
      d="M2 0h11M1 1h1m11 0h1M0 2h1m4 0h5m4 0h1M0 3h1m3 0h1m4 0h1m4 0h1M0 4h1m3 0h1m4 0h1m4 0h1M0 5h1m4 0h2m2 0h1m4 0h1M0 6h1m5 0h1m2 0h1m4 0h1M0 7h1m5 0h1m2 0h1m4 0h1M0 8h1m5 0h1m2 0h1m4 0h1M0 9h1m4 0h2m2 0h1m4 0h1M0 10h1m3 0h1m5 0h1m3 0h1M0 11h1m3 0h1m5 0h1m3 0h1M0 12h1m4 0h5m4 0h1M1 13h1m11 0h1M2 14h11"
    />
    <path
      stroke="#fbe18d"
      d="M2 1h11M1 2h1M1 3h1M1 4h1M1 5h1M1 6h1M1 7h1M1 8h1M1 9h1m-1 1h1m-1 1h1m-1 1h1"
    />
    <path
      stroke="#f8cf48"
      d="M2 2h3m5 0h3M2 3h2m6 0h3M2 4h2m6 0h4M2 5h3m5 0h4M2 6h4m4 0h4M2 7h4m4 0h3M2 8h4m4 0h3M2 9h3m5 0h3M2 10h2m7 0h2M2 11h2m7 0h2M2 12h3m5 0h3"
    />
    <path
      stroke="#f59b2c"
      d="M13 2h1m-1 1h1m-1 4h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1M2 13h11"
    />
    <path
      stroke="#fff"
      d="M5 3h4M5 4h4M7 5h2M7 6h2M7 7h2M7 8h2M7 9h2m-4 1h5m-5 1h5"
    />
  </svg>
)

export default One
