import React from "react"

const Three = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 16 16">
    <path
      stroke="#000"
      d="M2 0h11M1 1h1m11 0h1M0 2h1m3 0h7m3 0h1M0 3h1m2 0h1m6 0h2m2 0h1M0 4h1m2 0h1m7 0h1m2 0h1M0 5h1m3 0h4m3 0h1m2 0h1M0 6h1m4 0h2m4 0h1m2 0h1M0 7h1m5 0h1m3 0h2m2 0h1M0 8h1m3 0h4m3 0h1m2 0h1M0 9h1m2 0h1m7 0h1m2 0h1M0 10h1m2 0h1m6 0h2m2 0h1M0 11h1m3 0h7m3 0h1M0 12h1m13 0h1M1 13h1m11 0h1M2 14h11"
    />
    <path
      stroke="#fbe18d"
      d="M2 1h11M1 2h1M1 3h1M1 4h1M1 5h1M1 6h1M1 7h1M1 8h1M1 9h1m-1 1h1m-1 1h1m-1 1h1"
    />
    <path
      stroke="#f8cf48"
      d="M2 2h2m7 0h2M2 3h1m9 0h1M2 4h1m9 0h1M2 5h2m8 0h1M2 6h3m7 0h1M2 7h4m6 0h1M2 8h2m8 0h1M2 9h1m9 0h1M2 10h1m9 0h1M2 11h2m7 0h2M2 12h11"
    />
    <path
      stroke="#f59b2c"
      d="M13 2h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1M2 13h11"
    />
    <path stroke="#fff" d="M4 3h6M4 4h7M8 5h3M7 6h4M7 7h3M8 8h3M4 9h7m-7 1h6" />
  </svg>
)
export default Three
