import React from "react"

const Two = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 16 16">
    <path
      stroke="#000"
      d="M2 0h11M1 1h1m11 0h1M0 2h1m4 0h5m4 0h1M0 3h1m3 0h1m4 0h2m3 0h1M0 4h1m2 0h1m6 0h1m3 0h1M0 5h1m2 0h1m2 0h2m2 0h1m3 0h1M0 6h1m2 0h1m2 0h1m3 0h1m3 0h1M0 7h1m2 0h3m3 0h2m3 0h1M0 8h1m3 0h1m3 0h3m3 0h1M0 9h1m2 0h1m6 0h1m3 0h1M0 10h1m2 0h1m6 0h1m3 0h1M0 11h1m3 0h7m3 0h1M0 12h1m13 0h1M1 13h1m11 0h1M2 14h11"
    />
    <path
      stroke="#fbe18d"
      d="M2 1h11M1 2h1M1 3h1M1 4h1M1 5h1M1 6h1M1 7h1M1 8h1M1 9h1m-1 1h1m-1 1h1m-1 1h1"
    />
    <path
      stroke="#f8cf48"
      d="M2 2h3m5 0h3M2 3h2m7 0h2M2 4h1m8 0h2M2 5h1m8 0h2M2 6h1m8 0h2M2 7h1m8 0h2M2 8h2m7 0h2M2 9h1m8 0h2M2 10h1m8 0h2M2 11h2m7 0h2M2 12h11"
    />
    <path
      stroke="#f59b2c"
      d="M13 2h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1m-1 1h1M2 13h11"
    />
    <path
      stroke="#fff"
      d="M5 3h4M4 4h6M4 5h2m2 0h2M4 6h2m1 0h3M6 7h3M5 8h3M4 9h6m-6 1h6"
    />
  </svg>
)

export default Two
