import React from "react"
import One from "../components/icons/one"
import Two from "../components/icons/two"
import Three from "../components/icons/three"

import reactStringReplace from "react-string-replace"
import { doShuffleResponses } from "../utils/shuffle"

const Card = ({ q, checkResponse }) => {
  const [shuffle, setShuffle] = React.useState([])
  const [selected, setSelected] = React.useState(false)
  const [valid, setValid] = React.useState(null)

  React.useEffect(() => {
    const handleKeyDown = e => {
      if ([49, 50, 51].indexOf(e.keyCode) === -1) return
      setSelected(true)
      if (e.keyCode === 49) {
        checkResponse(q, 1 === valid)
      } else if (e.keyCode === 50) {
        checkResponse(q, 2 === valid)
      } else if (e.keyCode === 51) {
        checkResponse(q, 3 === valid)
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [valid])

  React.useEffect(() => {
    const s = doShuffleResponses(q.responses, q.valid, q)
    setShuffle(s)
    s.map((s, index) => {
      if (s.valid) {
        setValid(index + 1)
      }
    })
  }, [q, q.valid, q.responses])

  return (
    <div className="flex flex-col items-center">
      <div className="p-3 w-full text-xl bg-yellow-100 border-2 border-yellow-200 text-center drop-shadow-lg">
        {reactStringReplace(q.question, "_", (match, i) => (
          <span
            key={i}
            className="h-6 mx-2 w-24 bg-yellow-200 inline-block"
          ></span>
        ))}
      </div>
      <ul className="my-4 text-sm w-fit">
        {shuffle.map((res, index) => (
          <li
            key={index}
            onClick={() => {
              setSelected(true)
              checkResponse(q, res.valid)
            }}
            className={`${
              selected
                ? res.valid
                  ? "transition ease-in-out delay-250 animate-shuffle border-green-400 hover:border-green-400 bg-green-200 hover:bg-green-200"
                  : "transition ease-in-out delay-250 animate-shuffle border-red-400 hover:border-red-400 bg-red-200 hover:bg-red-200"
                : "border-white"
            } flex items-center mb-2 cursor-pointer border-2 py-1 px-2 group`}
          >
            <div className="h-12 w-12 self-start mr-8 transition group-hover:scale-125">
              {index === 0 ? <One /> : index === 1 ? <Two /> : <Three />}
            </div>
            <div
              className={`${
                selected
                  ? ""
                  : "group-hover:underline decoration-4 decoration-gray-200 underline-offset-4"
              } transition flex-1 ease-in-out delay-250 group-hover:scale-105 justify-center text-center flex items-center`}
            >
              {res.text}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Card
