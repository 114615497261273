export const getColor = (s, actualId) => {
  if (s.id === actualId) {
    return "text-yellow-600 bg-yellow-200"
  }

  if (!s.visited) {
    return "text-gray-600 bg-gray-200"
  }

  if (s.success && s.failed) {
    return "text-orange-700 bg-orange-400"
  }

  if (s.success) {
    return "text-green-700 bg-green-400"
  }

  if (s.failed) {
    return "text-red-800 bg-red-400"
  }

  return "text-yellow-600 bg-yellow-200"
}
