import React from "react"
import { Link } from "gatsby"
import { getScore, getResponses } from "../utils/score"
import { getColor } from "../utils/color"

const HeatMap = ({ state, date, actualId }) => {
  if (!state || state.length === 0) {
    return null
  }

  return (
    <div className="flex items-center justify-between mb-8 w-fit mx-auto">
      <div className="border-gray-200 bg-gray-100 border-2 px-3 py-2 mr-4 drop-shadow-lg">
        <div className="text-gray-800 text-3xl drop-shadow-xl">
          {getScore(date)}%
        </div>
      </div>
      <div className="flex-1 flex flex-wrap bg-white bg-red-200 bg-red-400 bg-red-600 bg-green-200 bg-green-400 bg-green-600 bg-transparent">
        {getResponses(date).map((q, index) =>
          q.success ? (
            <span
              key={q.id}
              className={`w-10 h-8 mr-1 mb-1 flex justify-center items-center block ${getColor(
                q,
                actualId
              )}`}
            >
              {index + 1}
            </span>
          ) : (
            <Link
              key={q.id}
              to={`/valencia/${date}/${q.id}`}
              className={`w-10 h-8 mr-1 mb-1 block flex justify-center items-center ${getColor(
                q,
                actualId
              )}`}
            >
              {index + 1}
            </Link>
          )
        )}
      </div>
    </div>
  )
}

export default HeatMap
